body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#home-main {
  height: 500px;
  @media (max-width: 768px) {
    background-size: 100%;
    height: 300px;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    background-size: 100%;
    height: 300px;
    
  }
  @media (min-width: 993px) {
    background-size: 100%;
    height: 500px;
  }
  @media (max-width: 576px) {
    background-size: 100%;
    height: 150px;
  }
  background-repeat: no-repeat;
  background-position: left bottom;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-image: url("../public/image/main.jpeg");
  /* background-position: left bottom -25px; */
  @include breakpoint(max, 768px) {
    background-image: url("../public/image/main.jpeg");
    background-position: center bottom;
    /* background-size: cover; */
  }
  @include breakpoint(max, 576px) {
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: 40% bottom; */
    justify-content: center;
  }

}